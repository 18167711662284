import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from '../axios'

const initialState = {
    data: null,
};

export const dashboardData = createAsyncThunk('Dashboard/dashboardData', async (includePreviousYear) => {
    try {
        const response = await axios.get(`/dashboard?includePreviousYear=${includePreviousYear}`);
        return response.data;
    } catch (error) {
        const customError = {
            name: error.code,
            message: error.message,
        };
        throw customError;
    }
});


export const dashboardSlice = createSlice({
    name: 'Dashboard',
    initialState,
    reducers:{},
    extraReducers:{
        [dashboardData.fulfilled]: (state, action) => {
            state.data = action.payload;
        },

        /* [dashboardData.fulfilled]: (state, action) => {
            state.data = action.payload;
        }, */
    }
});

export default dashboardSlice.reducer;