import React, { useEffect, useState } from "react";
import { Spin, Row, Col, Card, Space, Dropdown } from "antd";
import { LoadingOutlined, UpCircleFilled, DownCircleFilled, FundFilled, UsergroupDeleteOutlined, WifiOutlined, CloseCircleOutlined, CreditCardOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { dashboardData } from "../../redux/dashboardSlice";
import TransactionsStatistics from "./TransactionsStatistics";
import { useTranslation } from "react-i18next";
import TicketSVG from "../../icons/ticket.svg";
import { Label } from "bizcharts";

const Dashboard = ({ loading = false }) => {

  const { data } = useSelector((state) => state?.dashboard);
  const { t } = useTranslation()
  const [makingApiCall, setMakingApiCall] = useState(null)
  const [includePreviousYearValue, setIncludePreviousYearValue] = useState(false)
  const [includePreviousYearLabel, setIncludePreviousYearLabel] = useState(t("dashboard.lastYear"))
  const dispatch = useDispatch();

  const fetchDashboardData = (includePreviousYear) => {
    dispatch(dashboardData(includePreviousYear))
      .unwrap()
      .then((data) => {
        setMakingApiCall(true)})
      .catch(() => setMakingApiCall(false));
  }

  useEffect(() => {
    fetchDashboardData(includePreviousYearValue);
  }, [dispatch]);

  const includePreviousYearAPI = ({key}) => {
    setIncludePreviousYearValue(key == 1 ? false : true);
    setIncludePreviousYearLabel(key == 1 ? t("dashboard.lastYear") : t("dashboard.last2Year"))
    fetchDashboardData(key == 1 ? false : true);
  }

  const items = [
    {
      key: "1",
      label: t("dashboard.lastYear")
    },
    {
      key: "2",
      label: t("dashboard.last2Year")
    }
  ]

  return (
    <Spin
      spinning={loading}
      size="large"
      indicator={<LoadingOutlined style={{ fontSize: 54 }} spin />}
      tip={
        <div>
          <h2>Loading...</h2>
        </div>
      }
    >
      <>
        <Row gutter={12}>
          <Col span={6} >
            <Card
              title={t("dashboard.totalTicketsSold")}
              style={{
                width: 300,
                //padding : "0px"
              }}
              bodyStyle={{padding : "16px"}}
            >
              <div style={{display:"flex", justifyContent: "space-evenly", alignItems: "center"}} >
                <p style={{fontSize: 16, fontWeight: "bold"}} >{data?.totalTicketSold}</p>
                {/* <UpCircleFilled style={{color: "green", fontSize: "25px" }} /> */}
                <img src={TicketSVG} style={{ width: "25px", height: "25px" }} />
              </div>
            </Card>
          </Col>
          <Col span={6} >
            <Card
              title={t("dashboard.totalTravelCardsSold")}
              style={{
                width: 300,
              }}
              bodyStyle={{padding : "16px"}}
            >
              <div style={{display:"flex", justifyContent: "space-evenly"}} >
                <p style={{fontSize: 16, fontWeight: "bold"}} >{data?.totalCardSold}</p>
                {/* <DownCircleFilled style={{color: "red", fontSize: "25px" }} /> */}
                <CreditCardOutlined style={{fontSize: "25px" }} />
              </div>
            </Card>
          </Col>
          <Col span={6} >
            <Card
              title={t("dashboard.totalRevenue")}
              style={{
                width: 300,
              }}
              bodyStyle={{padding : "8px"}}
            >
              <div style={{display:"flex", justifyContent: "space-evenly"}} >
                <p style={{display: "flex" , flexDirection: "column"}} >
                <span  style={{fontSize: 16, fontWeight: "bold"}}>€ {data?.totalRevenue?.totalRevenue}</span>
                <span style={{marginTop: "2px"}}>{t("dashboard.cash")} € {data?.totalRevenue?.cash} {t("dashboard.card")} € {data?.totalRevenue?.card} </span>
                </p>
                <FundFilled style={{color: "#1677ff", fontSize: "25px" }} />
              </div>
            </Card>
          </Col>
          <Col span={6} >
            <Card
              title={t("dashboard.totalDrivers")}
              style={{
                width: 300,
              }}
              bodyStyle={{padding : "16px"}}
            >
              <div style={{display:"flex", justifyContent: "space-evenly"}} >
                <p style={{fontSize: 16, fontWeight: "bold"}} >{data?.totalDrivers}</p>
                <UsergroupDeleteOutlined style={{ fontSize: "25px" }}/>
              </div>
            </Card>
          </Col>
        </Row>


        <Row gutter={1} style={{marginTop: "10px", marginBottom: "10px"}}>
        {
          <Card
              title={
                <div style={{fontSize: "20px", display:"flex", justifyContent: "space-between"}} >
                {t("dashboard.transactionsByMonth")}
                <Dropdown menu={{ items, onClick : includePreviousYearAPI }} style={{fontSize: "25px"}}>
                  <a onClick={(e) => e.preventDefault()}>
                    <Space>
                    {includePreviousYearLabel}
                    </Space>
                  </a>
                </Dropdown>
                </div>

              }
              style={{
                width: "50%",
              }}
              bodyStyle={{padding : "16px"}}
            >      
          <TransactionsStatistics data={data?.transactionPerMonth} />
          </Card>} 
        </Row>       

        <Row gutter={12}>
          <Col span={6} >
            <Card
              title={t("dashboard.totalKiosks")}
              style={{
                width: 300,
                //padding : "0px"
              }}
              bodyStyle={{padding : "8px"}}
            >
              <div style={{display:"flex", justifyContent: "space-evenly"}} >
                <p style={{fontSize: 16, fontWeight: "bold"}} >{data?.kioskOnlineOfflineMachine?.onlineMachines}</p>
                <WifiOutlined style={{color: "green", fontSize: "25px" }} />
              </div>
              <div style={{display:"flex", justifyContent: "space-evenly"}} >
                <p style={{fontSize: 16, fontWeight: "bold"}} >{data?.kioskOnlineOfflineMachine?.offlineMachines}</p>
                <CloseCircleOutlined style={{color: "red", fontSize: "25px" }} />
              </div>
            </Card>
          </Col>
          <Col span={6} >
            <Card
              title={t("dashboard.totalPortableKiosks")}
              style={{
                width: 300,
              }}
              bodyStyle={{padding : "8px"}}
            >
              <div style={{display:"flex", justifyContent: "space-evenly"}} >
                <p style={{fontSize: 16, fontWeight: "bold"}} >{data?.portableKioskOnlineOfflineMachine?.onlineMachines}</p>
                <WifiOutlined style={{color: "green", fontSize: "25px" }} />
              </div>
              <div style={{display:"flex", justifyContent: "space-evenly"}} >
                <p style={{fontSize: 16, fontWeight: "bold"}} >{data?.portableKioskOnlineOfflineMachine?.offlineMachines}</p>
                <CloseCircleOutlined style={{color: "red", fontSize: "25px" }} />
              </div>
            </Card>
          </Col>
          <Col span={6} >
            <Card
              title={t("dashboard.handheldKiosks")}
              style={{
                width: 300,
              }}
              bodyStyle={{padding : "8px"}}
            >
              <div style={{display:"flex", justifyContent: "space-evenly"}} >
                <p style={{fontSize: 16, fontWeight: "bold"}} >{data?.handheldKioskOnlineOfflineMachine?.onlineMachines}</p>
                <WifiOutlined style={{color: "green", fontSize: "25px" }} />
              </div>
              <div style={{display:"flex", justifyContent: "space-evenly"}} >
                <p style={{fontSize: 16, fontWeight: "bold"}} >{data?.handheldKioskOnlineOfflineMachine?.offlineMachines}</p>
                <CloseCircleOutlined style={{color: "red", fontSize: "25px" }} />
              </div>
            </Card>
          </Col>
          <Col span={6} >
            <Card
              title={t("dashboard.validators")}
              style={{
                width: 300,
              }}
              bodyStyle={{padding : "8px"}}
            >
              <div style={{display:"flex", justifyContent: "space-evenly"}} >
                <p style={{fontSize: 16, fontWeight: "bold"}} >{data?.validatorOnlineOfflineMachine?.onlineMachines}</p>
                <WifiOutlined style={{color: "green", fontSize: "25px" }} />
              </div>
              <div style={{display:"flex", justifyContent: "space-evenly"}} >
                <p style={{fontSize: 16, fontWeight: "bold"}} >{data?.validatorOnlineOfflineMachine?.offlineMachines}</p>
                <CloseCircleOutlined style={{color: "red", fontSize: "25px" }} />
              </div>
            </Card>
          </Col>
        </Row>
      </>
    </Spin>
  );
};

export default Dashboard;
